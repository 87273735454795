import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";
import { COUNTRIES } from "../../config/constants";
import DropDownComponent from "./../DropDown";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 16px) scale(1)",
      textTransform: "capitalize",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
    "& .MuiInputBase-root": {
      background: "#FFF",
      padding: 3,
    },
  },
}))(TextField);

const CityFilter = ({ value, onChange }) => {
  const { t } = useTranslation("common");

  return (
    <>
      {COUNTRIES.find(({ code, label }) => code === value) ? (
        <DropDownComponent
          hideTopLabel
          label={t("city")}
          fullWidth
          options={COUNTRIES}
          value={value}
          onChange={(value) => {
            onChange(value);
          }}
        />
      ) : value ? (
        <BootstrapInput
          variant="outlined"
          label={t("city")}
          fullWidth
          placeholder={t("city")}
          value={value}
        />
      ) : null}
    </>
  );
};

export default CityFilter;
