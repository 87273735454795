import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import useStyles from "./style";
import useUsersStatus from "./../../states/users";
import PopUp from "./../Popup";
import AuthModal from "./AuthModal";

export default function AuthButton({ children, label, noButton }) {
  const classes = useStyles();
  const isLoggesIn = useUsersStatus();

  const [showPopup, setShowPopup] = React.useState(false);
  const loginPopup = (e) => {
    console.log('Clicked')
    setShowPopup(true);
  };

  useEffect(() => {
    if (isLoggesIn) {
      setShowPopup(false);
    }
  }, [isLoggesIn]);

  return (
    <React.Fragment>
      {isLoggesIn ? (
        children
      ) : noButton ? (
        React.cloneElement(children, { onClick: () => {
          console.log('hee')
        }, href: '#' }) // children
      ) : (
        <Button
          type="button"
          onClick={loginPopup}
          variant="contained"
          color="secondary"
        >
          {label}
        </Button>
      )}

      {showPopup ? (
        <PopUp
          title="Login"
          open={showPopup}
          handleClose={() => {
            setShowPopup(false);
          }}
        >
          <AuthModal />
        </PopUp>
      ) : null}
    </React.Fragment>
  );
}
