import React from "react";
import Link from "next/link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  items: {
    paddingBottom: theme.spacing(2),
  },
}));

export default function AddItemCardBox({ title, description }) {
  const classes = useStyles();

  return (
    <Grid container align="center">
      <Grid xs={12} className={classes.items}>
        <Typography component="h3" variant="h5">
          {title}
        </Typography>
      </Grid>
      <Grid xs={12} className={classes.items}>
        <Typography component="p" variant="subtitle1">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}
