import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  filedWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
