import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    background: "url(/images/home-bg-black.png) center center repeat",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    color: "#FFF",
  },
}));

const BlackContainer = ({ children, ...others }) => {
  const classes = useStyles();

  return <div className={classes.main} {...others}>{children}</div>;
};

export default BlackContainer;
