import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonGroup, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useTranslation from "next-translate/useTranslation";
import AdditemCardBox from "./../AddItemCardBox";

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    flex: "1",
  },
  buttonStyleRoomRental: {
    flex: "3",
  },
  addBoxHeading: {
    marginBottom: theme.spacing(3),
  },
  addBoxContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  formFieldWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

const WhyUs = () => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Grid container xs={12} className={classes.addBoxContainer}>
      <Grid item xs={12} align="center" className={classes.addBoxHeading}>
        <Typography variant="h4" component="h2">
          {t("why_find_sports_partner")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box p={2}>
              <AdditemCardBox
                title={t("number_of_service_years")}
                description={t("number_of_service_years_description")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={2}>
              <AdditemCardBox
                title={t("trustworty")}
                description={t("trustworty_description")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={2}>
              <AdditemCardBox
                title={t("safe")}
                description={t("safe_description")}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhyUs;
