import React, { Fragment, useEffect } from "react";
import Link from "next/link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useTranslation from "next-translate/useTranslation";
import useUsersStatus from "./../../states/users";
import PopUp from "./../Popup";
import AuthModal from "../Auth/AuthModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  anchorStyle: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  textStyle: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
}));

export default function ActionCard({ href, label, title, skipLogin = false }) {
  const classes = useStyles();
  const isLoggesIn = useUsersStatus();

  const [showPopup, setShowPopup] = React.useState(false);
  const loginPopup = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  useEffect(() => {
    if (isLoggesIn) {
      setShowPopup(false);
    }
  }, [isLoggesIn]);
  const { t } = useTranslation("common");
  
  return (
    <Fragment>
      {isLoggesIn || skipLogin ? (
        <Link href={href} legacyBehavior>
          <a className={classes.anchorStyle} title={title || t(label)}>
            <Typography
              variant="body1"
              color="secondary"
              className={classes.textStyle}
              component="p"
            >
              {t(label)}
            </Typography>
          </a>
        </Link>
      ) : (
        <a className={classes.anchorStyle} title={title || t(label)} href={href} onClick={loginPopup}>
          <Typography
            variant="body1"
            color="secondary"
            className={classes.textStyle}
            component="p"
          >
            {t(label)}
          </Typography>
        </a>
      )}
      {showPopup ? (
        <PopUp
          title="Login"
          open={showPopup}
          handleClose={() => {
            setShowPopup(false);
          }}
        >
          <AuthModal />
        </PopUp>
      ) : null}
    </Fragment>
  );
}
