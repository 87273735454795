import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/Input";
import useTranslation from "next-translate/useTranslation";
import CityFilter from "./../CityFilter";
import useStyles from "./style";
import screenSize from "./../../states/screen";
import {
  countrySpecificConfig,
  SPORTS_CATEGORY,
  PREFERRED_GENDER,
} from "../../config/constants";
import DropDownComponent from "./../DropDown";

const FilterComponent = ({
  selectedSuggestions,
  setFilterValues,
  filterValues,
  hideListingType,
  isAdmin,
  showCity = true,
  hidePriceAndBedroom = false,
  hidePropertyType = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDesktop = screenSize();
  const { t } = useTranslation("common");

  const guessCity = () => {
    if (!window.Intl) {
      return "";
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let city = timezone.split("/")[1];
    return city.toLowerCase();
  };

  const city = filterValues.city;
  const freeText = filterValues.freeText;
  const district = filterValues.district;
  const preferredGender = filterValues.preferredGender;
  const sportsCategory = filterValues.sportsCategory;

  const [disabled, setDisabled] = useState(!!city);

  const updatevalue = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };


  useEffect(() => {
    if (disabled !== !filterValues.city) {
      setDisabled(!filterValues.city);
    }
  }, [filterValues]);

  return (
    <Grid container disableGutters={true} spacing={1} xs={12}>
      <Grid className={classes.filedWrapper} item xs={12} sm={3}>
        <CityFilter
          value={city}
          onChange={(data) => {
            updatevalue("city", data);
          }}
        />
      </Grid>

      <Grid className={classes.filedWrapper} item xs={12} sm={7}>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInput
            fullWidth
            value={freeText}
            variant="filled"
            placeholder={t("search")}
            onChange={(e) => {
              updatevalue("freeText", e.target.value);
            }}
            disabled={disabled}
            disableUnderline={true}
            inputProps={{
              style: {
                padding: "12px 8px",
                border: "1px solid #ced4da",
                borderRadius: "4px",
                background: "#FFF",
                color: "#000",
              },
            }}
          />
        </FormControl>
      </Grid>

      {isDesktop ? (
        <Grid item disableGutters={true} xs={12} sm={2}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            color="primary"
            style={{ height: "100%", maxHeight: "42px" }}
            disabled={disabled}
          >
            {t("search")}
          </Button>
        </Grid>
      ) : null}

      {city &&
        countrySpecificConfig[city] &&
        countrySpecificConfig[city].DISTRICT && (
          <Grid className={classes.filedWrapper} item xs={12} sm={3}>
            <DropDownComponent
              label={t("district")}
              hideTopLabel
              fullWidth
              options={countrySpecificConfig[city].DISTRICT}
              value={district}
              disabled={disabled}
              onChange={(value) => {
                updatevalue("district", value);
              }}
            />
          </Grid>
        )}

      <Grid className={classes.filedWrapper} item xs={12} sm={3}>
        <DropDownComponent
          hideTopLabel
          label={t("category")}
          fullWidth
          options={SPORTS_CATEGORY}
          disabled={disabled}
          value={sportsCategory}
          onChange={(value) => {
            updatevalue("sportsCategory", value);
          }}
        />
      </Grid>

      <Grid className={classes.filedWrapper} item xs={12} sm={3}>
        <DropDownComponent
          hideTopLabel
          label={t("preferred_gender")}
          fullWidth
          options={PREFERRED_GENDER}
          disabled={disabled}
          value={preferredGender}
          onChange={(value) => {
            updatevalue("preferredGender", value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FilterComponent;
